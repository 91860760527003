/* Gallery container */
.gallery {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(128px, 1fr));
    gap: 16px;
    padding: 20px;
    background-color: #f5f5f5;
}

/* Individual gallery items */
.gallery-item {
    overflow: hidden;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s, box-shadow 0.3s;
}

/* Hover effect */
.gallery-item:hover {
    transform: scale(1.05);
    box-shadow: 0 8px 10px rgba(0, 0, 0, 0.15);
}

/* Images inside gallery items */
.gallery-item img {
    width: 100%;
    height: 128px;
    object-fit: cover;
    border-radius: 8px;
}

/* Responsive media queries for mobile devices */
@media (max-width: 768px) {
    .gallery {
        grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
        gap: 12px;
    }

    .gallery-item img {
        height: 100px; /* Slightly smaller images for smaller screens */
    }
}

@media (max-width: 480px) {
    .gallery {
        grid-template-columns: repeat(auto-fill, minmax(80px, 1fr));
        gap: 8px;
    }

    .gallery-item img {
        height: 80px; /* Smaller size for very small screens */
    }
}
