/* Container for cards */
.cards-container img {
    width: 100%;
    height: 200px;
    object-fit: cover;
}

.card-content {
    padding: 15px;
}

.card-title {
    margin: 0 0 10px;
    font-size: 1.5rem;
    color: #333;
}

.card-description {
    margin: 0;
    font-size: 1rem;
    color: #666;
}

/* Individual card styles */
.card {
    flex: 1 1 calc(25% - 40px); /* 3 cards per row, with space between */
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    min-width: 250px;
}

/* Media query for mobile devices */
@media (max-width: 768px) {
    .card {
        flex: 1 1 100%; /* Stack cards on mobile */
    }
}