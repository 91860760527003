/* General Reset */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
/* Body Styling */
body {
    font-family: 'Arial', sans-serif;
    line-height: 1.6;
    color: #333;
    background-color: #f9f9f9;
    margin: 0;
    padding: 0;
}

.App {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

/* Main Content */
.main-content {
    flex-grow: 1;
    padding: 20px;
}

.section {
    margin-bottom: 40px;
    padding: 20px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.section h2 {
    margin-bottom: 15px;
    font-size: 1.8rem;
    color: #333;
}

.section p {
    font-size: 1.2rem;
    color: #666;
}


/* Mobile Styles */
@media (max-width: 768px) {
    .main-content {
        padding: 10px;
    }

    .section h2 {
        font-size: 1.5rem;
    }

    .section p {
        font-size: 1rem;
    }
}

/* Small Mobile Styles (i.e., phones under 480px wide) */
@media (max-width: 480px) {
    .section {
        padding: 10px;
    }

    .section h2 {
        font-size: 1.3rem;
    }

    .section p {
        font-size: 0.9rem;
    }
}