.form-container {
    width: 400px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

h2 {
    text-align: center;
    margin-bottom: 20px;
}

.form-content {
    display: flex;
    flex-direction: column;
}

label {
    font-weight: bold;
    margin-bottom: 5px;
}

input, select, textarea {
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 14px;
}

textarea {
    resize: vertical;
}

button {
    padding: 10px 15px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
}

button:hover {
    background-color: #45a049;
}

.dimension-inputs {
    display: flex;
    justify-content: space-between;
}

.dimension-inputs input {
    width: 30%;
}


.container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 20px;
}

.metadata {
    flex: 1;
    margin-right: 20px;
}

.image {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

img {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

/* Responsive layout for laptops and desktops */
@media (min-width: 768px) {
    .result-container {
        flex-direction: row;
    }

    .metadata {
        text-align: left;
        background: black;
    }

    .image {
        text-align: right;
    }
}

/* Responsive layout for mobile devices */
@media (max-width: 767px) {
    .result-container {
        flex-direction: column;
    }

    .metadata {
        margin-top: 20px;
        text-align: center;
    }

    .image {
        text-align: center;
        margin-bottom: 20px;
    }
}